import React from 'react';
import NavOne from './NavOne';

const HeaderOne = () => {
    return(
        <>
        <header className="ploting-header">           
            <NavOne />
        </header>
        </>
    )
}

export default HeaderOne;