import React from 'react';
import NavOne from './NavOne';

const HeaderTwo = () => {
    return(
        <>
        <header className="ploting-header style-two">
            <NavOne />
        </header>
        </>
    )
}

export default HeaderTwo;