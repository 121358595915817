// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ZoomableImage.css */
.containerImage {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
}

.zoomable-image {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    z-index: 1; /* Wartość z-index musi być większa niż dla overlay, aby obraz był na wierzchu */
}
.showImages {
    display: flex;
    justify-content: center; /* Centrowanie poziome */
    align-items: center; /* Centrowanie pionowe */
    height: 100vh; /* Wysokość całego widoku */
    margin-top: 5vh;
}
.overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.714);
    z-index: 2; /* Warstwa overlay powinna być na wierzchu */
}

.zoomed .overlay {
    display: block;
}
`, "",{"version":3,"sources":["webpack://./src/supportscripts/ZoomableImage.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,sCAAsC;IACtC,UAAU,EAAE,gFAAgF;AAChG;AACA;IACI,aAAa;IACb,uBAAuB,EAAE,wBAAwB;IACjD,mBAAmB,EAAE,wBAAwB;IAC7C,aAAa,EAAE,2BAA2B;IAC1C,eAAe;AACnB;AACA;IACI,aAAa;IACb,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,sCAAsC;IACtC,UAAU,EAAE,4CAA4C;AAC5D;;AAEA;IACI,cAAc;AAClB","sourcesContent":["/* ZoomableImage.css */\n.containerImage {\n    position: relative;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    /* height: 100vh; */\n}\n\n.zoomable-image {\n    max-width: 100%;\n    max-height: 100%;\n    cursor: pointer;\n    transition: transform 0.3s ease-in-out;\n    z-index: 1; /* Wartość z-index musi być większa niż dla overlay, aby obraz był na wierzchu */\n}\n.showImages {\n    display: flex;\n    justify-content: center; /* Centrowanie poziome */\n    align-items: center; /* Centrowanie pionowe */\n    height: 100vh; /* Wysokość całego widoku */\n    margin-top: 5vh;\n}\n.overlay {\n    display: none;\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(255, 255, 255, 0.714);\n    z-index: 2; /* Warstwa overlay powinna być na wierzchu */\n}\n\n.zoomed .overlay {\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
