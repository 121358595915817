import React from 'react';
import UserApproval from '../../components/blog-components/UserApproval';


const UserApprovalPage = () => {
    return (
        <>
            <UserApproval />
        </>
    )
}
    
export default UserApprovalPage;