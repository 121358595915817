import React from 'react';
import HeaderThree from '../../common/header/HeaderThree';
import Banner from '../../components/section-components/Banner';
import ServicesTwo from '../../components/section-components/ServicesTwo';
import AboutTwo from '../../components/section-components/AboutTwo';
import ProjectsOne from '../../components/section-components/ProjectsOne';
import TestimonialTwo from '../../components/section-components/TestimonialTwo';
import Pricing from '../../components/section-components/Pricing';
import TeamTwo from '../../components/section-components/TeamTwo';
import Counter from '../../components/section-components/Counter';
import NewsTwo from '../../components/section-components/NewsTwo';
import FooterOne from '../../common/footer/FooterOne';

const HomeThree = () => {
    return (
        <>
        <HeaderThree />
        
        <Banner />
        
        <ServicesTwo />
        
        <AboutTwo />
        
        <ProjectsOne />
        
        <TestimonialTwo />
        
        <Pricing />
        
        <TeamTwo />
        
        <Counter />
        
        <NewsTwo />

        <FooterOne />
        </>
    )
}
    
export default HomeThree;