import React from 'react';
import UserDetailsChanges from '../../components/blog-components/UserDetailsChanges';


const UserChangesPage = () => {
    return (
        <>
            <UserDetailsChanges />
        </>
    )
}
    
export default UserChangesPage;