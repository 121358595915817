import React from 'react';
import UserRemover from '../../components/blog-components/UserRemover';


const UserRemovePage = () => {
    return (
        <>
            <UserRemover />
        </>
    )
}
    
export default UserRemovePage;